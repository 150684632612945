export var getInitials = function (name) {
    // Check if the name is provided and not empty
    if (!name || name.trim().length === 0) {
        return ''; // or return a placeholder like 'N/A'
    }
    return name
        .split(' ')
        .map(function (part) { return part[0]; })
        .join('')
        .toUpperCase();
};
