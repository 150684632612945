// SubscriptionSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { stripePayment, cancelSubscription, getCurrentSubscriptionPlan } from "./subscriptionThunk";
var initialState = {
    loading: false,
    error: null,
    currentSubscription: null
};
var subscriptionSlice = createSlice({
    name: "subscription",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(stripePayment.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(stripePayment.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(stripePayment.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(cancelSubscription.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(cancelSubscription.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(cancelSubscription.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(getCurrentSubscriptionPlan.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(getCurrentSubscriptionPlan.fulfilled, function (state, action) {
            state.loading = false;
            state.currentSubscription = action.payload.data;
        })
            .addCase(getCurrentSubscriptionPlan.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        });
    },
});
export default subscriptionSlice.reducer;
