// userThunk.ts
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
// User thunk
export var updateProfile = createAsyncThunk("user/updateProfile", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.patch("/user/update-profile", data)];
            case 1:
                response = _e.sent();
                toast.success(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) || "Profile has been updated successfully");
                return [2 /*return*/, response.data];
            case 2:
                error_1 = _e.sent();
                toast.error((_d = (_c = (_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : "User data not updated!");
                return [2 /*return*/, thunkAPI.rejectWithValue("User data not updated!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var changePassword = createAsyncThunk("user/changePassword", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.patch("/user/change-password", data)];
            case 1:
                response = _e.sent();
                toast.success(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) || "Password has been updated successfully!");
                return [2 /*return*/, response.data];
            case 2:
                error_2 = _e.sent();
                toast.error((_d = (_c = (_b = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : "Password cannot be changed!");
                return [2 /*return*/, thunkAPI.rejectWithValue("Password cannot be changed!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getUsersList = createAsyncThunk("user/getUsersList", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.get("/user/get-all-users?page=".concat(data.activePage, "&pageSize=").concat(data.itemsPerPage))];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_3 = _d.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue((_c = (_b = (_a = error_3 === null || error_3 === void 0 ? void 0 : error_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Get user list failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var activeInactiveUser = createAsyncThunk("user/activeInactiveUser", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post('/user/activeInactiveUsers', data)];
            case 1:
                response = _g.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_4 = _g.sent();
                toast.error((_c = (_b = (_a = error_4 === null || error_4 === void 0 ? void 0 : error_4.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "User status not updated!");
                return [2 /*return*/, thunkAPI.rejectWithValue((_f = (_e = (_d = error_4 === null || error_4 === void 0 ? void 0 : error_4.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message) !== null && _f !== void 0 ? _f : "User status not updated!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var deleteUser = createAsyncThunk("user/activeInactiveUsers", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.delete('/user/deleteUserAccount', {
                        params: {
                            email: data.email,
                        },
                    })];
            case 1:
                response = _g.sent();
                toast.success("User has been deleted successfully!");
                return [2 /*return*/, response.data];
            case 2:
                error_5 = _g.sent();
                toast.error((_c = (_b = (_a = error_5 === null || error_5 === void 0 ? void 0 : error_5.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "User deleted failed!");
                return [2 /*return*/, thunkAPI.rejectWithValue((_f = (_e = (_d = error_5 === null || error_5 === void 0 ? void 0 : error_5.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message) !== null && _f !== void 0 ? _f : "User deleted failed!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
