// src/app/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import auth from '../features/auth/authSlice';
import subscription from '../features/subscription/subscriptionSlice';
import user from '../features/user/userSlice';
var rootReducer = combineReducers({
    auth: auth,
    subscription: subscription,
    user: user
});
export default rootReducer;
