var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback } from 'react';
import { Container, Row } from 'react-bootstrap';
import Sidebar from '../Sidebar';
import Header from '../Header';
var Layout = function (_a) {
    var title = _a.title, children = _a.children;
    var _b = useState(false), isSidebarOpen = _b[0], setSidebarOpen = _b[1];
    var toggleSidebar = useCallback(function () {
        setSidebarOpen(function (prevState) { return !prevState; });
    }, []);
    return (_jsx(Container, __assign({ fluid: true, className: "dashboard-bg" }, { children: _jsxs(Row, { children: [_jsx(Sidebar, { isOpen: isSidebarOpen, toggleSidebar: toggleSidebar }), _jsx("div", __assign({ className: "content px-1 main-panel" }, { children: _jsxs("div", __assign({ className: "content-wrapper" }, { children: [_jsx(Header, { title: title, toggleSidebar: toggleSidebar }), _jsx("div", __assign({ className: "mt-4" }, { children: children }))] })) }))] }) })));
};
export default React.memo(Layout);
