import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
var PublicRoute = function (_a) {
    var element = _a.element;
    var isAuthenticated = useMemo(function () {
        var accessToken = localStorage.getItem('accessToken');
        var refreshToken = localStorage.getItem('refreshToken');
        return !!accessToken && !!refreshToken;
    }, []);
    return isAuthenticated ? _jsx(Navigate, { to: "/dashboard", replace: true }) : element;
};
export default PublicRoute;
