import axios from 'axios';
// Define the local storage utility functions with types
var localStorageUtil = {
    setItem: function (key, value) {
        var object = { value: value };
        window.localStorage.setItem(key, JSON.stringify(object));
    },
    getItem: function (key) {
        var item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
};
// Set the JWT token in local storage and configure axios with it
export var setDefaultJWT = function (jwt) {
    window.localStorage.setItem("accessToken", jwt);
    axios.defaults.headers.common['Authorization'] = "Bearer ".concat(jwt);
};
export var setRefreshToken = function (token) {
    window.localStorage.setItem("refreshToken", token);
};
export var setUserData = function (user) {
    window.localStorage.setItem('user', JSON.stringify(user));
};
export var getUserData = function () {
    var item = window.localStorage.getItem("user");
    return item ? JSON.parse(item) : null;
};
export var getUserEmail = function () {
    var item = getUserData();
    return (item === null || item === void 0 ? void 0 : item.email) || '';
};
export var getUserRole = function () {
    var item = getUserData();
    return (item === null || item === void 0 ? void 0 : item.userType) || '';
};
// Remove JWT token from local storage
export var removeDefaultJWT = function () {
    window.localStorage.removeItem("accessToken");
};
// Get the JWT token from local storage
export var getJWTToken = function () {
    var token = window.localStorage.getItem("accessToken");
    return token;
};
// Set a redirect URL in local storage
export var setRedirectUrl = function (url) {
    window.localStorage.setItem("redirect_url", url);
};
// Get the redirect URL from local storage
export var getRedirectUrl = function () {
    return window.localStorage.getItem("redirect_url");
};
// Remove the redirect URL from local storage
export var removeRedirectUrl = function () {
    window.localStorage.removeItem("redirect_url");
};
