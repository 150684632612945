var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { setDefaultJWT, setRefreshToken, setUserData } from "utils/storage";
var initialState = {
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    loading: false,
    error: null,
    email: null,
    forgotPassEmail: null,
    forgotPassOTP: false,
    changePassword: false
};
// Login thunk
export var login = createAsyncThunk("auth/login", function (credentials, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/login", credentials)];
            case 1:
                response = _e.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_1 = _e.sent();
                console.log((_b = (_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
                toast.error(((_d = (_c = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) || "Incorrect email or password.");
                return [2 /*return*/, thunkAPI.rejectWithValue("Login failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Login with Google thunk
export var loginWithGoogle = createAsyncThunk("auth/social-login", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/social-login", data)];
            case 1:
                response = _e.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_2 = _e.sent();
                console.log((_b = (_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
                toast.error((_d = (_c = error_2 === null || error_2 === void 0 ? void 0 : error_2.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message);
                return [2 /*return*/, thunkAPI.rejectWithValue("Login failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Signup thunk
export var signup = createAsyncThunk("auth/signup", function (userData, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/signup", userData)];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_3 = _d.sent();
                toast.error((_c = (_b = (_a = error_3 === null || error_3 === void 0 ? void 0 : error_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Signup failed");
                return [2 /*return*/, thunkAPI.rejectWithValue("Signup failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Verify Email thunk
export var verifyEmail = createAsyncThunk("auth/verify-email", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/verify-email", data)];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_4 = _d.sent();
                toast.error((_c = (_b = (_a = error_4 === null || error_4 === void 0 ? void 0 : error_4.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Email verification failed");
                return [2 /*return*/, thunkAPI.rejectWithValue("Email verification failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Resend Verify Email thunk
export var resendVerifyEmail = createAsyncThunk("auth/resend-verify-email", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/resend-verify-email", data)];
            case 1:
                response = _d.sent();
                toast.success("Verification email code has been sent. Please check your inbox.");
                return [2 /*return*/, response.data];
            case 2:
                error_5 = _d.sent();
                toast.error((_c = (_b = (_a = error_5 === null || error_5 === void 0 ? void 0 : error_5.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Email verification failed");
                return [2 /*return*/, thunkAPI.rejectWithValue("Email verification failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Forgot Password thunk
export var forgotPassword = createAsyncThunk("auth/forgot-password", function (email, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/forgot-password", { email: email })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, email];
            case 2:
                error_6 = _a.sent();
                // toast.error(error?.response?.data?.message ?? "Forgot Password Failed!");
                return [2 /*return*/, thunkAPI.rejectWithValue("Forgot Password Failed!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Reset Password Verify Code thunk
export var resetPasswordVerifyCode = createAsyncThunk("auth/auth-reset-code", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.post("/auth/auth-reset-code", data)];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_7 = _d.sent();
                toast.error((_c = (_b = (_a = error_7 === null || error_7 === void 0 ? void 0 : error_7.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Code verification failed");
                return [2 /*return*/, thunkAPI.rejectWithValue("Code verification failed")];
            case 3: return [2 /*return*/];
        }
    });
}); });
// Reset Password thunk
export var resetPassword = createAsyncThunk("auth/reset-password", function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_8;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.patch("/auth/reset-password", data)];
            case 1:
                response = _d.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_8 = _d.sent();
                toast.error((_c = (_b = (_a = error_8 === null || error_8 === void 0 ? void 0 : error_8.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "Reset Password Failed!");
                return [2 /*return*/, thunkAPI.rejectWithValue("Reset Password Failed!")];
            case 3: return [2 /*return*/];
        }
    });
}); });
var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: function (state) {
            state.accessToken = null;
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
        },
        resetForgotPasswordData: function (state) {
            state.forgotPassEmail = null;
            state.forgotPassOTP = false;
            state.changePassword = false;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(login.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(login.fulfilled, function (state, action) {
            state.loading = false;
            state.accessToken = action.payload.accessToken.token;
            state.refreshToken = action.payload.refreshToken;
            setDefaultJWT(action.payload.accessToken.token);
            setRefreshToken(action.payload.accessToken.refreshToken);
            setUserData(action.payload.data);
        })
            .addCase(login.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(loginWithGoogle.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(loginWithGoogle.fulfilled, function (state, action) {
            state.loading = false;
            state.accessToken = action.payload.accessToken.token;
            state.refreshToken = action.payload.refreshToken;
            setDefaultJWT(action.payload.accessToken.token);
            setRefreshToken(action.payload.accessToken.refreshToken);
            setUserData(action.payload.data);
        })
            .addCase(loginWithGoogle.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(signup.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(signup.fulfilled, function (state, action) {
            var _a;
            state.loading = false;
            state.email = ((_a = action.payload.data) === null || _a === void 0 ? void 0 : _a.email) || null;
            state.accessToken = action.payload.accessToken.token;
            state.refreshToken = action.payload.accessToken.refreshToken;
            setUserData(action.payload.data);
        })
            .addCase(signup.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(verifyEmail.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(verifyEmail.fulfilled, function (state, action) {
            state.loading = false;
            setDefaultJWT(state.accessToken);
            setRefreshToken(state.refreshToken);
        })
            .addCase(verifyEmail.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(resendVerifyEmail.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(resendVerifyEmail.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(resendVerifyEmail.rejected, function (state, action) {
            state.loading = false;
        })
            .addCase(forgotPassword.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(forgotPassword.fulfilled, function (state, action) {
            state.loading = false;
            state.forgotPassEmail = action.payload || '';
        })
            .addCase(forgotPassword.rejected, function (state, action) {
            state.loading = false;
        })
            .addCase(resetPassword.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(resetPassword.fulfilled, function (state, action) {
            state.loading = false;
            state.changePassword = true;
        })
            .addCase(resetPassword.rejected, function (state, action) {
            state.loading = false;
        })
            .addCase(resetPasswordVerifyCode.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(resetPasswordVerifyCode.fulfilled, function (state, action) {
            state.loading = false;
            state.forgotPassOTP = true;
        })
            .addCase(resetPasswordVerifyCode.rejected, function (state, action) {
            state.loading = false;
        });
    },
});
export var logout = (_a = authSlice.actions, _a.logout), resetForgotPasswordData = _a.resetForgotPasswordData;
export default authSlice.reducer;
