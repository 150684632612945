var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import "./styles/main.scss";
var rootElement = document.getElementById("root");
var CLIENT_ID = "536558954224-hqncqfdavf1nca624vc005lvia7u58jt.apps.googleusercontent.com";
if (rootElement) {
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(React.StrictMode, { children: _jsxs(Provider, __assign({ store: store }, { children: [_jsx(GoogleOAuthProvider, __assign({ clientId: CLIENT_ID }, { children: _jsx(App, {}) })), _jsx(ToastContainer, {})] })) }));
    reportWebVitals();
}
else {
    console.error("Failed to find the root element");
}
