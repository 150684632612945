// UserSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { updateProfile, getUsersList, deleteUser, activeInactiveUser } from "./userThunk";
var initialState = {
    loading: false,
    error: null,
    usersList: [],
    totalUsers: 0,
};
var userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(updateProfile.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(updateProfile.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(updateProfile.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(getUsersList.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(getUsersList.fulfilled, function (state, action) {
            state.loading = false;
            state.usersList = action.payload.data.users;
            state.totalUsers = action.payload.data.totalCount;
        })
            .addCase(getUsersList.rejected, function (state, action) {
            state.loading = false;
            state.error = action.payload;
        })
            .addCase(activeInactiveUser.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(activeInactiveUser.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(activeInactiveUser.rejected, function (state, action) {
            state.loading = false;
        })
            .addCase(deleteUser.pending, function (state) {
            state.loading = true;
            state.error = null;
        })
            .addCase(deleteUser.fulfilled, function (state, action) {
            state.loading = false;
        })
            .addCase(deleteUser.rejected, function (state, action) {
            state.loading = false;
        });
    },
});
export default userSlice.reducer;
