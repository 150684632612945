var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Typography from 'components/Typography';
import { getInitials } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { getUserData } from "utils/storage";
import { logout } from '../../features/auth/authSlice';
var Header = function (_a) {
    var title = _a.title, toggleSidebar = _a.toggleSidebar;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var user = getUserData();
    var buttonRef = useRef(null);
    var popupRef = useRef(null);
    var menuItems = [
        { label: 'Edit Profile', action: function () { return redirectionUrl('/edit-profile'); } },
        { label: 'Change Password', action: function () { return redirectionUrl('/change-password'); } },
        { label: 'Terms of Service' },
        { label: 'Privacy Policy' },
        { label: 'Billing' },
        {
            label: 'Logout', action: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, dispatch(logout())];
                        case 1:
                            _a.sent();
                            navigate('/login');
                            return [2 /*return*/];
                    }
                });
            }); }
        },
    ];
    var togglePopup = useCallback(function () {
        setIsOpen(function (prev) { return !prev; });
    }, []);
    var handleClickOutside = useCallback(function (event) {
        if (popupRef.current &&
            buttonRef.current &&
            !popupRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, []);
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    var redirectionUrl = function (route) {
        navigate(route);
        togglePopup();
    };
    return (_jsxs("div", __assign({ className: "d-flex w-100 justify-content-between align-items-center header" }, { children: [_jsxs("div", __assign({ className: "d-flex justify-content-start align-items-center" }, { children: [_jsx(FontAwesomeIcon, { icon: faBars, className: "menu-icon me-3", onClick: toggleSidebar }), _jsx(Typography, __assign({ size: "22", weight: 600, mobilesize: "20" }, { children: title }))] })), _jsx("div", __assign({ className: "profile-initials", ref: buttonRef, onClick: togglePopup }, { children: (user === null || user === void 0 ? void 0 : user.fullName) && getInitials(user.fullName) })), isOpen && (_jsx("div", __assign({ ref: popupRef, className: "profile-popup" }, { children: _jsx("ul", { children: menuItems
                        .filter(function (item) {
                        if (user.loginType === "google" && item.label === "Change Password")
                            return false;
                        if (user.userType === "admin") {
                            return ['Edit Profile', 'Change Password', 'Logout'].includes(item.label);
                        }
                        return item;
                    })
                        .map(function (item, index) { return (_jsx("li", __assign({ onClick: item.action }, { children: item.label }), index)); }) }) })))] })));
};
export default React.memo(Header);
